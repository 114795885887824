import { useCallback, useMemo, useRef, useState } from 'react';
import type { DialogState } from '~/components/TolaDialog';
import { Deferred } from '~/utils/deferred';
import { invariant } from '~/utils/invariant';

export type UseDialogStateOpts = {
  /**
   * @default 'closed'
   */
  initialState?: 'open' | 'closed';
};

export interface UseDialogStateReturn extends DialogState {
  open: () => void;
  close: () => void;
  show: boolean;
}

/**
 * A hook to manage the state of a dialog.
 *
 * Usage example:
 * ```tsx
 * const dialogState = useDialogState();
 *
 * return <>
 *  <button onClick={dialogState.open}>Open</button>
 *  <TolaDialog {...dialogState} />
 * </>
 * ```
 * @deprecated use a `useState()` or a `useQueryParamToggle()` hook instead
 */
export function useDialogState(
  opts: UseDialogStateOpts = {},
): UseDialogStateReturn & { toggle: () => void } {
  const { initialState = 'closed' }: typeof opts = opts;
  const [isOpen, setIsOpen] = useState(initialState === 'open');
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []);
  return {
    isOpen,
    // QOL: Allow passing the hook return directly to a `Modal` component
    // isOpen will be depracted in favor of `show` in the future
    // This is to better match with headlessui dialog
    show: isOpen,
    open: useCallback((event?: React.MouseEvent<HTMLElement>) => {
      // prevent e.g. a link from navigating or a button triggering submit
      event?.preventDefault();
      setIsOpen(true);
    }, []),
    close,
    // QOL: Allow passing the hook return directly to a `Dialog` component
    onClose: close,
    toggle,
  };
}

/**
 * A hook to manage the state of a confirm dialog and `await` the result.
 * This is useful for when you want to wait for the user to confirm an action before continuing.
 * @note
 * This could be extended to return data from the dialog, but for now it just returns a boolean.
 * @example
 * ```tsx
 * function DeleteButton() {
 *   const confirmDialogState = useConfirmDialogState();
 *   return (
 *    <>
 *      <button onClick={() => {
 *        void confirmDialogState.confirm()
 *          .then(confirmed => {
 *            if (confirmed) {
 *              // Do the delete
 *            }
 *       }}>
 *         Delete
 *       </button>
 *     <TolaDialog {...confirmDialogState} />
 *   </>
 *  )
 * )
 * ```
 */
export function useConfirmDialogState() {
  const deferredRef = useRef<Deferred<boolean> | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const confirm = useCallback((): Promise<boolean> => {
    if (deferredRef.current) {
      deferredRef.current.reject(new Error('Another dialog was opened'));
    }
    const deferred = (deferredRef.current = new Deferred());
    void deferred.finally(() => {
      setIsOpen(false);
      deferredRef.current = null;
    });
    setIsOpen(true);

    return deferred;
  }, []);

  const resolve = useCallback((confirmed: boolean) => {
    invariant(!deferredRef.current, 'No dialog is open to resolve');
    deferredRef.current?.resolve(confirmed);
  }, []);

  const onConfirm = useCallback(() => resolve(true), [resolve]);
  const onCancel = useCallback(() => resolve(false), [resolve]);

  return useMemo(
    () => ({
      /**
       * Whether the dialog is open.
       */
      isOpen,
      /**
       * Open the dialog and return a `Promise` that resolves when the dialog is closed.
       */
      confirm,
      /**
       * The dialog completed successfully.
       */
      onConfirm,
      /**
       * The dialog was canceled.
       */
      onCancel,
      /**
       * QOL: Allow passing the hook return directly to a `Dialog` component
       * @alias onConfirm
       */
      onClose: onCancel,
    }),
    [isOpen, confirm, onConfirm, onCancel],
  );
}

export type UseConfirmDialogStateReturn = ReturnType<
  typeof useConfirmDialogState
>;
