import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  TRPCClientError,
  TRPCClientErrorLike,
  createTRPCProxyClient,
  httpBatchLink,
  httpLink,
  loggerLink,
  splitLink,
} from '@trpc/client';
import { withTRPC } from '@trpc/next';

import type { Session } from 'next-auth';
import { SessionProvider, getSession } from 'next-auth/react';
import type { AppProps } from 'next/app';
import type { AppType } from 'next/dist/shared/lib/utils';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import posthog from 'posthog-js';
import { useEffect, useMemo } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import 'styles/globals.css';
import 'styles/next.css';
import { useQueryClient } from '@tanstack/react-query';
import { observable } from '@trpc/server/observable';
import getConfig from 'next/config';
import { LinkProps } from 'next/link';
import type { SuperJSONResult } from 'superjson/dist/types';
import type { PublicConfig } from '~/app/_utils/publicConfig';
import type { ViewerMeOutput } from '~/components/AppContext';
import {
  AppViewerProvider,
  OrganizationProvider,
} from '~/components/AppContext';
import { PostHogProvider } from '~/components/Posthog';
import { RedirectWithSpinner } from '~/components/Redirect';
import { RedirectToTola2Guard } from '~/components/RedirectToTola2Guard';
import { SupportedAppOrganizationGuard } from '~/components/SupportedAppOrganizationGuard';
import { Toaster } from '~/components/Toast';
import { UrlMessageAside } from '~/components/UrlMessageAside';
import { Debug } from '~/components/debug/Debug';
import type { NextPageWithLayout } from '~/components/layout';
import { PageLayout, Single } from '~/components/layout';
import { useViewerUtils } from '~/generated/trpc/viewer';
import { useOnMount } from '~/hooks/useOnMount';
import {
  detectVersionChangeLink,
  useHardNavigationOnVersionChange,
} from '~/hooks/usePromptOnNavigation';
import { PublicConfigProvider } from '~/hooks/usePublicConfig';
import { useReducedMotion } from '~/hooks/useReducedMotion';
import { AccountingUrlMessage } from '~/modules/accounting-sync/ui/AccoutingUrlMessage';
import { Toaster as T2Toaster } from '~/modules/ui/primitives/toaster';
import { TooltipProvider } from '~/modules/ui/primitives/tooltip';
import type { AppRouter } from '~/server/routers/_app';
import { useInitDatadog } from '~/utils/datadog';
import { transformer } from '~/utils/transformer';
import { noop, promiseProps, stringOrNull } from '~/utils/utility';

const nextConfig = getConfig();
const publicConfig = nextConfig.publicRuntimeConfig as PublicConfig;

interface SSRData {
  viewer: ViewerMeOutput;
  session: Session | null;
}

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function useIsLoggedInRoute() {
  const router = useRouter();
  const isLoggedInRoute = /^\/org(\/|$)/.test(router.pathname);

  return isLoggedInRoute;
}

function useRouterOrganizationSlug() {
  const router = useRouter();
  const isLoggedInRoute = useIsLoggedInRoute();

  return isLoggedInRoute ? stringOrNull(router.query.slug) : null;
}
const App: AppType = (_props) => {
  useReducedMotion();
  useInitDatadog(publicConfig);
  useHardNavigationOnVersionChange(publicConfig);

  const queryClient = useQueryClient();

  const props = _props as AppPropsWithLayout;

  const viewerUtils = useViewerUtils();
  const pageProps = props.pageProps as
    | undefined
    | {
        ssrData: SuperJSONResult;
        redirect: string | null;
      };
  const ssrData = useMemo(() => {
    // Hydrate SSR-data from `getInitialProps`
    if (!pageProps?.ssrData) {
      return null;
    }
    const ssrData = transformer.deserialize<SSRData>(pageProps.ssrData);

    if (ssrData.viewer && !viewerUtils.public.me.getData()) {
      // This ensures that the first call to `trpc.viewer.public.me.useQuery()` will already be cached
      viewerUtils.public.me.setData(undefined, ssrData.viewer);
    }
    return ssrData;
  }, [pageProps?.ssrData]);

  const PageComponent = props.Component;

  // <PageLayout> is a good default for application pages under /org/
  // <Single> is a better default for pages outside of /org/ such as legal pages.
  const isOrgPage = props.router.asPath.startsWith('/org/');
  const getLayout =
    PageComponent.getLayout ??
    (isOrgPage
      ? (page) => <PageLayout>{page}</PageLayout>
      : (page) => <Single>{page}</Single>);

  useEffect(() => {
    console.log('⚙️ Config:', publicConfig);
  }, []);

  useOnMount(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview');

    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  });

  const isLoggedInRoute = useIsLoggedInRoute();
  const orgSlug = useRouterOrganizationSlug();

  const pageWithLayout = getLayout(<props.Component {...props.pageProps} />);

  if (
    typeof window !== 'undefined' &&
    (publicConfig.APP_ENV === 'test' || publicConfig.APP_ENV === 'development')
  ) {
    window.invalidateQueries = () => queryClient.invalidateQueries();
  }

  if (pageProps?.redirect) {
    // Prevent the page from rendering if we're redirecting as it can cause errors
    // The redirect will be handled on the server, this `RedirectWithSpinner` should never be rendered but doesn't hurt to have it here
    return (
      <RedirectWithSpinner href={pageProps.redirect as LinkProps['href']} />
    );
  }

  return (
    <PublicConfigProvider value={publicConfig}>
      <Head>
        {/* Default title - will be overridden if a title is defined further down the tree */}
        <title>Tola</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          property="og:title"
          content="The simple, fast and free tool for businesses to pay and get paid | Tola"
        />
        <meta
          property="og:description"
          content="Pay your vendors. Get paid by your customers. All in one place. Tola is the simplest way to manage your business payments."
        />
        <meta
          property="og:image"
          content={`${publicConfig.APP_URL}/img/social-sharing-preview.jpg`}
        />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      <IntercomProvider
        appId={publicConfig.INTERCOM_APP_ID}
        autoBoot={publicConfig.APP_ENV !== 'test'}
        autoBootProps={{ hideDefaultLauncher: true, alignment: 'left' }}
      >
        <PostHogProvider>
          <SessionProvider session={ssrData?.session}>
            <TooltipProvider>
              <AppViewerProvider
                requireMfa={
                  PageComponent.requireMfaIfLoggedIn ?? isLoggedInRoute ?? false
                }
              >
                {/* We automatically include the OrganizationProvider when we are on `/org/...`-routes */}
                {orgSlug ? (
                  <OrganizationProvider
                    slug={orgSlug}
                    // Force-render all children when organization changes
                    key={orgSlug}
                  >
                    <SupportedAppOrganizationGuard>
                      <RedirectToTola2Guard>
                        {pageWithLayout}
                      </RedirectToTola2Guard>
                    </SupportedAppOrganizationGuard>
                  </OrganizationProvider>
                ) : (
                  pageWithLayout
                )}
              </AppViewerProvider>
            </TooltipProvider>
          </SessionProvider>
        </PostHogProvider>
        <UrlMessageAside />
        {/* URL Message (accounting only) for new Tola UI */}
        <AccountingUrlMessage />
      </IntercomProvider>
      <Toaster />
      {/* Toaster for new Tola UI */}
      <T2Toaster />
      <Debug>
        <ReactQueryDevtools initialIsOpen={false} />
      </Debug>
    </PublicConfigProvider>
  );
};

/**
 * `getInitialProps` is a Next.js lifecycle method that runs on the server-side on the initial page load.
 * We use this to fetch data that we want to have available on first load of every request and to do redirects if needed.
 */
App.getInitialProps = async (opts) => {
  const { req, res } = opts.ctx;

  if (typeof window !== 'undefined' || !res || !req?.url) {
    // Only run the following code on the server
    // `res` and `req.url` are always available on the server, but TS doesn't know that
    return {
      pageProps: {},
    };
  }

  // APP_INTERNAL_URL is asserted by `~/server/env`
  const url = `${process.env.APP_INTERNAL_URL}/api/trpc`;

  const client = createTRPCProxyClient<AppRouter>({
    links: [
      httpBatchLink({
        url,
        transformer,
        headers() {
          // Pass along cookies from the browser to the server
          return {
            cookie: req?.headers.cookie,
          };
        },
      }),
    ],
  });

  // getSparseSession prevents passing in the full body of an HTTP request to
  // getSession. This in turn allows session gathering to work on pages where a
  // POST is required. This should only affect sessions as we prevent tRPC based
  // mutations later via the HTTP POST method guards.
  const getSesssionSparse = () => {
    const request = opts.ctx.req;
    if (!request) {
      throw new Error('request missing on context');
    }
    return getSession({
      req: { headers: { cookie: request.headers.cookie } },
    });
  };

  const ssrData: SSRData = await promiseProps({
    viewer: client.viewer.public.me.query().catch(() => null),
    session: getSesssionSparse(),
  });

  const getProps = (opts?: { redirect?: string }) => {
    const redirect = opts?.redirect;
    if (redirect) {
      res.setHeader('location', redirect);
      res.statusCode = 302;
    }
    return {
      pageProps: {
        ssrData: transformer.serialize(ssrData),
        redirect: redirect ?? null,
      },
    };
  };

  if (!req.url.startsWith('/org')) {
    // If it's not a signed in route, don't do any extra checks
    return getProps();
  }

  if (!ssrData.viewer) {
    // If it's a signed in route and the user is unauthenticated, redirect to login
    return getProps({
      redirect: `/auth/login?callbackUrl=${encodeURIComponent(req.url)}`,
    });
  }

  // If it's a signed in route and the user is authenticated, make sure they are a member
  const orgSlugRegex = /^\/org\/([^\/\?]+)(.*)$/;
  const orgSlugMatch = req.url.match(orgSlugRegex);
  const orgSlug = orgSlugMatch?.[1];
  const orgSlugSuffix = orgSlugMatch?.[2];

  // the new page is being selected. this is required because we're intercepting this later
  if (orgSlug === 'new') {
    return getProps();
  }
  // the deeplink page is selected
  if (orgSlug === '+') {
    return getProps({
      redirect: `/org?to=${encodeURIComponent(orgSlugSuffix ?? '/')}`,
    });
  }

  // No slug in the URL, meaning it's a route like `/org` or `/org/`
  if (!orgSlug) {
    return getProps();
  }

  // This is also determined in `AppContext.tsx` to check there too
  const membership = ssrData.viewer.memberships.find((it) => {
    return it.organization.slug === orgSlug;
  });

  // Redirect to organization list if you try to access an organization you're not a member of or if the org isn't active
  if (!membership) {
    return getProps({ redirect: '/org' });
  }

  return getProps();
};

const NUM_QUERY_RETRIES = 3;

export default withTRPC<AppRouter>({
  transformer,
  config() {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    return {
      /**
       * @link https://trpc.io/docs/links
       */
      links: [
        detectVersionChangeLink,
        // Prevent server-side fetches
        () => (opts) =>
          observable((observer) => {
            if (typeof window === 'undefined') {
              // If this happens, it means that a `useSuspenseQuery()` was called somewhere in the React-tree while server-side rendering
              // This generally happens on public page, since they are the only ones we server-side render
              // Workarounds:
              // a) Fetch the query in `getServerSideProps()` if you're on a public page
              // b) Wrap the `useSuspenseQuery()` in a `NoSSRSuspense` component if you're on an "app page"
              const message = `Tried fetching ${opts.op.path} while server-side rendering`;
              console.error(message);

              observer.error(new TRPCClientError(message));
              return;
            }
            return opts.next(opts.op).subscribe(observer);
          }),
        // adds pretty logs to your console in development and logs errors in production
        loggerLink({
          enabled: (opts) => {
            if (
              publicConfig.APP_ENV === 'development' ||
              publicConfig.APP_ENV === 'test'
            ) {
              return true;
            }
            return opts.direction === 'down' && opts.result instanceof Error;
          },
        }),
        /**
         * Opt-out of batching by setting `context.skipBatch = true`
         * @see https://trpc.io/docs/links/splitLink
         */
        splitLink({
          condition: (opts) => Boolean(opts.context.skipBatch),
          true: httpLink({
            url: `/api/trpc`,
            transformer,
          }),
          false: httpBatchLink({
            url: `/api/trpc`,
            transformer,
          }),
        }),
      ],
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      queryClientConfig: {
        logger: {
          error: noop,
          warn: noop,
          log: noop,
        },
        defaultOptions: {
          queries: {
            // Makes it so we can code on airplanes.
            // By default, react-query will not even attempt to query if it detects that we're offline
            networkMode:
              publicConfig.APP_ENV === 'development' ? 'always' : undefined,
            /**
             * By default, `react-query` automatically retries queries that fails and keep the `loading` state
             */
            retry(failureCount, _err) {
              const err = _err as never as TRPCClientErrorLike<AppRouter>;

              const httpStatus = err?.data?.httpStatus;
              if (httpStatus && httpStatus < 500) {
                // prevent refetch on non-5xx errors
                return false;
              }
              return failureCount < NUM_QUERY_RETRIES;
            },
          },
        },
      },
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: false,
})(App);
